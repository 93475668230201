function NotFound() {
  return (
    <div className="container">
      <h2>404 Not Found</h2>
      <p>Oops. That URL is incorrect.</p>
      <p>Please navigate to another page.</p>
    </div>
  );
}

export default NotFound;
